var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.expense.create_expense'),"icon":"tw-expense"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"validator",attrs:{"slim":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.expense.name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.expense.name'),"color":"tertiary","counter":"255","clearable":"","error-messages":errors},model:{value:(_vm.expense.name),callback:function ($$v) {_vm.$set(_vm.expense, "name", $$v)},expression:"expense.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.expense.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('expense-type-picker',{attrs:{"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.expense.type),callback:function ($$v) {_vm.$set(_vm.expense, "type", $$v)},expression:"expense.type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.expense.amount'),"rules":"required|min_value:0|max_value:9999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal.unsigned",modifiers:{"unsigned":true}}],attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.expense.amount'),"color":"tertiary","clearable":"","error-messages":errors},model:{value:(_vm.expense.requested.amount),callback:function ($$v) {_vm.$set(_vm.expense.requested, "amount", _vm._n($$v))},expression:"expense.requested.amount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('currency.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-picker',{attrs:{"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.expense.requested.code),callback:function ($$v) {_vm.$set(_vm.expense.requested, "code", $$v)},expression:"expense.requested.code"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.expense.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"format":"YYYY-MM-DDTHH:mm:ss","disabled":_vm.loading,"label":_vm.$t('hr.expense.date'),"error-messages":errors},model:{value:(_vm.expense.timestamp),callback:function ($$v) {_vm.$set(_vm.expense, "timestamp", $$v)},expression:"expense.timestamp"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":_vm.loading,"label":_vm.$t('global.paymentType'),"items":_vm.paymentTypes,"menu-props":{ offsetY: true },"item-text":function (item) { return _vm.$t(("hr.expense.payment_type." + (item.text))); },"item-value":"value","color":"tertiary"},model:{value:(_vm.expense.paymentType),callback:function ($$v) {_vm.$set(_vm.expense, "paymentType", $$v)},expression:"expense.paymentType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('travel-picker',{attrs:{"disabled":_vm.loading},model:{value:(_vm.expense.travel),callback:function ($$v) {_vm.$set(_vm.expense, "travel", $$v)},expression:"expense.travel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('expense-management-picker',{attrs:{"disabled":_vm.loading,"employee":_vm.$store.state.auth.user.id},model:{value:(_vm.expense.expenseManagement),callback:function ($$v) {_vm.$set(_vm.expense, "expenseManagement", $$v)},expression:"expense.expenseManagement"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('file-upload-picker',{ref:"fileUpload",attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.expense.attachment'),"storageType":"EXPENSE","onCreate":""},model:{value:(_vm.expense.attachment),callback:function ($$v) {_vm.$set(_vm.expense, "attachment", $$v)},expression:"expense.attachment"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('global.description'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"1","label":_vm.$t('global.description'),"color":"tertiary","counter":512,"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.expense.description),callback:function ($$v) {_vm.$set(_vm.expense, "description", $$v)},expression:"expense.description"}})]}}])})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{attrs:{"create":""},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }