<template>
  <app-page-layout :title="$t('hr.expense.create_expense')" icon="tw-expense" @close="leavePage">
    <template #content>
      <validation-observer ref="validator" slim>
        <v-row>
          <v-col cols="12">
            <validation-provider slim :name="$t('hr.expense.name')" rules="required|max:255" v-slot="{ errors }">
              <v-text-field
                v-model="expense.name"
                :disabled="loading"
                :label="$t('hr.expense.name')"
                color="tertiary"
                counter="255"
                clearable
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider slim :name="$t('hr.expense.type')" rules="required" v-slot="{ errors }">
              <expense-type-picker v-model="expense.type" :disabled="loading" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="9">
            <validation-provider
              slim
              :name="$t('hr.expense.amount')"
              rules="required|min_value:0|max_value:9999999"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model.number="expense.requested.amount"
                v-decimal.unsigned
                :disabled="loading"
                :label="$t('hr.expense.amount')"
                color="tertiary"
                clearable
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="3">
            <validation-provider slim :name="$t('currency.code')" rules="required" v-slot="{ errors }">
              <currency-picker v-model="expense.requested.code" :disabled="loading" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider slim :name="$t('hr.expense.date')" rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="expense.timestamp"
                format="YYYY-MM-DDTHH:mm:ss"
                :disabled="loading"
                :label="$t('hr.expense.date')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="expense.paymentType"
              :disabled="loading"
              :label="$t('global.paymentType')"
              :items="paymentTypes"
              :menu-props="{ offsetY: true }"
              :item-text="item => $t(`hr.expense.payment_type.${item.text}`)"
              item-value="value"
              color="tertiary"
            />
          </v-col>
          <v-col cols="12">
            <travel-picker v-model="expense.travel" :disabled="loading" />
          </v-col>
          <v-col cols="12">
            <expense-management-picker
              v-model="expense.expenseManagement"
              :disabled="loading"
              :employee="$store.state.auth.user.id"
            />
          </v-col>
          <v-col cols="12">
            <file-upload-picker
              v-model="expense.attachment"
              ref="fileUpload"
              :disabled="loading"
              :label="$t('hr.expense.attachment')"
              storageType="EXPENSE"
              onCreate
            />
          </v-col>
          <v-col cols="12">
            <validation-provider slim :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
              <v-textarea
                v-model="expense.description"
                rows="1"
                :label="$t('global.description')"
                color="tertiary"
                :counter="512"
                :disabled="loading"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer create>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Expense, { PaymentType } from "../model/Expense";

  export default {
    name: "ExpenseCreate",
    components: {
      ExpenseTypePicker: () => import("../components/expense-type-picker"),
      CurrencyPicker: () => import("@/components/selectpickers/currency-picker"),
      TravelPicker: () => import("@/components/selectpickers/TravelPicker"),
      ExpenseManagementPicker: () => import("@/components/selectpickers/ExpenseManagementPicker"),
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    data: vm => ({
      loading: false,
      expense: new Expense(),
      paymentTypes: vm.$helpers.fromEnumToArray(PaymentType)
    }),
    methods: {
      leavePage() {
        this.$router.replace({ name: "expenses" });
      },
      validateAndSave() {
        this.$refs.validator.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.$refs.fileUpload.sendFile().then(uploaded => {
              if (uploaded) {
                this.$api.expenseService
                  .save(this.expense)
                  .then(({ data }) => {
                    if (!data.error) {
                      this.$emit("success:save");
                      this.leavePage();
                    }
                  })
                  .catch(console.error)
                  .finally(() => (this.loading = false));
              }
            });
          }
        });
      }
    }
  };
</script>
